const createjs = window.createjs; // Get the createjs instance from window object

export function BachTilesCreate(cjs, an, canvas, stage, exportRoot) {
  var p; // shortcut to reference prototypes
  var lib = {};
  var ss = {};
  var img = {};
  lib.ssMetadata = [];

  (lib.AnMovieClip = function () {
    this.actionFrames = [];
    this.ignorePause = false;
    this.gotoAndPlay = function (positionOrLabel) {
      cjs.MovieClip.prototype.gotoAndPlay.call(this, positionOrLabel);
    };
    this.play = function () {
      cjs.MovieClip.prototype.play.call(this);
    };
    this.gotoAndStop = function (positionOrLabel) {
      cjs.MovieClip.prototype.gotoAndStop.call(this, positionOrLabel);
    };
    this.stop = function () {
      cjs.MovieClip.prototype.stop.call(this);
    };
  }).prototype = p = new cjs.MovieClip();
  // symbols:

  (lib.Untitled1 = function () {
    this.initialize(img.Untitled1);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled10 = function () {
    this.initialize(img.Untitled10);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled11 = function () {
    this.initialize(img.Untitled11);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled12 = function () {
    this.initialize(img.Untitled12);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled13 = function () {
    this.initialize(img.Untitled13);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled14 = function () {
    this.initialize(img.Untitled14);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled15 = function () {
    this.initialize(img.Untitled15);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled16 = function () {
    this.initialize(img.Untitled16);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled17 = function () {
    this.initialize(img.Untitled17);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled2 = function () {
    this.initialize(img.Untitled2);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled3 = function () {
    this.initialize(img.Untitled3);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled4 = function () {
    this.initialize(img.Untitled4);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled5 = function () {
    this.initialize(img.Untitled5);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled6 = function () {
    this.initialize(img.Untitled6);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled7 = function () {
    this.initialize(img.Untitled7);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled8 = function () {
    this.initialize(img.Untitled8);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Untitled9 = function () {
    this.initialize(img.Untitled9);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol17 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled17();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol16 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled16();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol15 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled15();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol14 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled14();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol13 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled13();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol12 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled12();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol11 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled11();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol10 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled10();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol9 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled9();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol8 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled8();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol7 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled7();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol6 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled6();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol5 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled5();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol4 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled4();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol3 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled3();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol2 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled2();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.Symbol1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Untitled1();

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 119, 119);

  (lib.CFLOGOsvg = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // CFLOGO_svg
    this.shape = new cjs.Shape();
    this.shape.graphics
      .f('#000000')
      .s()
      .p(
        'AAeA9Igmg4IgOAAIAAAgQAAANAPABIAAAKIg3AAIAAgKQAQgBAAgNIAAhKQAAgMgQgBIAAgKIBCAAQAsAAAAAgQAAANgIAIQgIAIgOACIAbAkIAHAIQAEADAHABIAAAKgAgTgvQgDADAAAHIAAAgIAMAAQARAAAHgFQAIgGAAgMQAAgVgbAAQgLAAgDACg'
      );
    this.shape.setTransform(131.275, 7.775);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics
      .f('#000000')
      .s()
      .p(
        'Ag6A9IAAgKQAPgBAAgNIAAhKQAAgMgPgBIAAgKIBfAAIARAjIgKAFQgRgcgfAAQgJAAgDACQgDACAAAHIAAAaIALAAQAIAAADgFQADgFABgMIAKAAIAAA4IgKAAQgBgOgEgEQgDgFgLAAIgHAAIAAAnQAAAHADACQAEACAKAAQAPAAALgHQAOgIAMgUIAJAFIgSApg'
      );
    this.shape_1.setTransform(118.925, 7.775);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics
      .f('#000000')
      .s()
      .p(
        'AAKA9IAAgKQAQgBAAgNIAAglIgzAAIAAAlQAAANAQABIAAAKIg3AAIAAgKQAPgBAAgNIAAhKQAAgMgPgBIAAgKIA3AAIAAAKQgQABAAAMIAAAaIAzAAIAAgaQAAgMgQgBIAAgKIA3AAIAAAKQgPABAAAMIAABKQAAANAPABIAAAKg'
      );
    this.shape_2.setTransform(105.525, 7.775);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics
      .f('#000000')
      .s()
      .p(
        'AgoAtQgRgSAAgbQABgaAQgRQAQgUAZABQAQgBAOAKIAAAAIADgHIAJAAIAMAoIgKAEQgHgSgNgIQgKgJgLABQgQgBgJAOQgJAOAAAXQAAAXAKAOQAKAOAPAAQAbAAAPgaIAKAFQgPAiglAAQgbAAgSgTg'
      );
    this.shape_3.setTransform(92.8, 7.75);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics
      .f('#000000')
      .s()
      .p(
        'AgOA9QgKgDgEgEIgCAHIgJAAIgOgsIAKgEQAKAUAOAKQAMAIALAAQALAAAHgFQAIgHAAgJQAAgIgEgDQgEgFgMgCIgbgIQgfgGAAgZQAAgOAKgKQAMgLATAAQARgBANAJIADgGIAJAAIALAkIgKACQgHgOgNgHQgKgHgLAAQgJAAgFAEQgGAFAAAIQAAAMAPADIAgAIQAQAFAIAGQAIAIAAANQAAARgNAMQgOANgXAAQgIAAgKgDg'
      );
    this.shape_4.setTransform(80.975, 7.75);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics
      .f('#000000')
      .s()
      .p(
        'AgaA9IAAgKQAPgBAAgNIAAhKQAAgMgPgBIAAgKIA1AAIAAAKQgPABAAAMIAABKQAAANAPABIAAAKg'
      );
    this.shape_5.setTransform(71.95, 7.775);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics
      .f('#000000')
      .s()
      .p(
        'Ag8BEIAAgMQARAAABgPIAAhSQgBgNgRgCIAAgLIBnAAIASAnIgLAFIgEgIQgRgWgdAAQgJAAgDADQgDADgBAIIAAAbIAMAAQAKAAAEgGQACgFABgOIALAAIAAA/IgLAAQgBgPgEgFQgEgFgLAAIgJAAIAAAoQABAPAQAAIAAAMg'
      );
    this.shape_6.setTransform(63.3, 7.075);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics
      .f('#000000')
      .s()
      .p(
        'Ag4A9IAAgKQAPgBAAgNIAAhKQAAgMgPgBIAAgKIA5AAIAAAKQgKAAgEADQgDADAAAJIAABIQAAAGACADQACACAIAAQAQAAALgGQANgHALgTIAKAEIgTApg'
      );
    this.shape_7.setTransform(45.775, 7.775);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics
      .f('#000000')
      .s()
      .p(
        'AAeA9Igng4IgNAAIAAAgQAAANAPABIAAAKIg3AAIAAgKQAQgBAAgNIAAhKQAAgMgQgBIAAgKIBCAAQAsAAAAAgQAAANgIAIQgJAHgNADIAaAkQAFAGAEACQADADAHABIAAAKgAgTgvQgDACAAAIIAAAgIAMAAQARAAAIgFQAHgGAAgMQAAgVgaAAQgMAAgDACg'
      );
    this.shape_8.setTransform(33, 7.775);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics
      .f('#000000')
      .s()
      .p(
        'AAMA+IAAgKQAQgBABgFIgCgHIgJgWIgsAAIgHARIgDAKQAAAGAOACIAAAKIgtAAIAAgKQAHgCADgEQAEgEAEgKIAohdIAUAAIAnBhQAFAJACADQADADAIABIAAAKgAgWAFIAjAAIgRgtg'
      );
    this.shape_9.setTransform(19.1, 7.675);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics
      .f('#000000')
      .s()
      .p(
        'AgsAyQgTgUAAgeQAAgcASgVQASgVAcAAQASAAAPALIAAgBIAFgHIAKAAIAMAtIgMADQgHgTgOgKQgMgIgNAAQgQAAgKAPQgLAPAAAaQAAAZALAQQAMAQARAAQAdAAARgeIAMAGQgSAmgpAAQgeAAgTgVg'
      );
    this.shape_10.setTransform(6.4, 7.075);

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({
          state: [
            { t: this.shape_10 },
            { t: this.shape_9 },
            { t: this.shape_8 },
            { t: this.shape_7 },
            { t: this.shape_6 },
            { t: this.shape_5 },
            { t: this.shape_4 },
            { t: this.shape_3 },
            { t: this.shape_2 },
            { t: this.shape_1 },
            { t: this.shape },
          ],
        })
        .wait(1)
    );

    this._renderFirstFrame();
  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 137.6, 14.2);

  // stage content:
  (lib.animationheader_HTML5Canvas = function (
    mode,
    startPosition,
    loop,
    reversed
  ) {
    if (loop == null) {
      loop = false;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_21
    this.instance = new lib.CFLOGOsvg('synched', 0);
    this.instance.setTransform(
      355.1,
      571.75,
      1.4315,
      1.4315,
      0,
      0,
      0,
      72.7,
      6.8
    );

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(150));

    // Layer_17
    this.instance_1 = new lib.Symbol17();
    this.instance_1.setTransform(
      602,
      212.4,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.8
    );
    new cjs.ButtonHelper(this.instance_1, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_1)
        .wait(10)
        .to(
          {
            regX: 52,
            scaleX: 0.9996,
            scaleY: 0.9717,
            y: 212.85,
          },
          10
        )
        .to({ regX: 52.1, scaleX: 0.9982, scaleY: 0.9628, y: 212.4 }, 10)
        .wait(60)
        .to(
          {
            regY: 50.2,
            y: 466.85,
          },
          10
        )
        .wait(9)
        .to({ y: 464.55 }, 0)
        .to({ y: 466.85 }, 40)
        .wait(1)
    );

    // Layer_16
    this.instance_2 = new lib.Symbol16();
    this.instance_2.setTransform(
      602,
      465.65,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.2
    );
    new cjs.ButtonHelper(this.instance_2, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_2)
        .wait(60)
        .to({ y: 465.05 }, 10)
        .wait(10)
        .to(
          {
            x: 471.3,
            y: 465.65,
          },
          10
        )
        .wait(19)
        .to({ y: 464.55 }, 0)
        .to({ y: 465.65 }, 40)
        .wait(1)
    );

    // Layer_15
    this.instance_3 = new lib.Symbol15();
    this.instance_3.setTransform(
      471.3,
      464.55,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.2
    );
    new cjs.ButtonHelper(this.instance_3, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_3)
        .wait(10)
        .to({ x: 340.7 }, 10)
        .wait(50)
        .to({ y: 465.05 }, 0)
        .wait(20)
        .to({ y: 464.55 }, 0)
        .wait(19)
        .to({ x: 340.6 }, 0)
        .to({ x: 340.7 }, 40)
        .wait(1)
    );

    // Layer_14
    this.instance_4 = new lib.Symbol14();
    this.instance_4.setTransform(
      340.7,
      338.8,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.2
    );
    new cjs.ButtonHelper(this.instance_4, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_4)
        .wait(10)
        .to({ x: 210.05 }, 10)
        .wait(10)
        .to({ y: 464.55 }, 10)
        .wait(30)
        .to({ y: 465.05 }, 0)
        .wait(20)
        .to({ y: 464.55 }, 0)
        .wait(19)
        .to(
          {
            regX: 51.6,
            x: 209.55,
          },
          0
        )
        .to({ regX: 52.1, x: 210.05 }, 40)
        .wait(1)
    );

    // Layer_13
    this.instance_5 = new lib.Symbol13();
    this.instance_5.setTransform(
      340.7,
      464.55,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.2
    );
    new cjs.ButtonHelper(this.instance_5, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_5)
        .to({ x: 79.35 }, 10)
        .wait(30)
        .to(
          {
            regY: 51.9,
            scaleY: 0.9633,
            y: 466.1,
          },
          0
        )
        .wait(10)
        .to({ regY: 50.2, scaleY: 0.9628, y: 464.55 }, 0)
        .wait(10)
        .to(
          {
            regX: 51.6,
            scaleX: 0.9979,
            x: 78.85,
          },
          0
        )
        .wait(10)
        .to(
          {
            regX: 52.1,
            scaleX: 0.9982,
            x: 79.35,
            y: 465.05,
          },
          0
        )
        .wait(20)
        .to({ y: 464.55 }, 0)
        .wait(60)
    );

    // Layer_12
    this.instance_6 = new lib.Symbol12();
    this.instance_6.setTransform(
      602,
      87.85,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.8
    );
    new cjs.ButtonHelper(this.instance_6, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_6)
        .wait(100)
        .to(
          {
            regY: 50.2,
            y: 338.2,
          },
          9
        )
        .to({ y: 338.8 }, 40)
        .wait(1)
    );

    // Layer_11
    this.instance_7 = new lib.Symbol11();
    this.instance_7.setTransform(
      602,
      338.8,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.2
    );
    new cjs.ButtonHelper(this.instance_7, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_7)
        .wait(10)
        .to(
          {
            regX: 52,
            scaleX: 0.9996,
            scaleY: 0.9717,
            y: 339.2,
          },
          10
        )
        .to({ regX: 52.1, scaleX: 0.9982, scaleY: 0.9628, y: 338.8 }, 10)
        .wait(20)
        .to(
          {
            regX: 51.6,
            scaleX: 0.9979,
            x: 601.5,
          },
          10
        )
        .to(
          {
            regX: 52.1,
            scaleX: 0.9982,
            x: 602,
          },
          10
        )
        .wait(10)
        .to({ x: 471.3 }, 10)
        .wait(19)
        .to({ y: 338.2 }, 0)
        .to({ y: 338.8 }, 40)
        .wait(1)
    );

    // Layer_10
    this.instance_8 = new lib.Symbol10();
    this.instance_8.setTransform(
      471.3,
      88.5,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.2
    );
    new cjs.ButtonHelper(this.instance_8, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_8)
        .wait(20)
        .to({ y: 338.8 }, 10)
        .wait(30)
        .to({ x: 340.7 }, 10)
        .wait(39)
        .to(
          {
            x: 340.6,
            y: 338.2,
          },
          0
        )
        .to({ x: 340.7, y: 338.8 }, 40)
        .wait(1)
    );

    // Layer_9
    this.instance_9 = new lib.Symbol9();
    this.instance_9.setTransform(
      471.3,
      338.8,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.2
    );
    new cjs.ButtonHelper(this.instance_9, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_9)
        .wait(10)
        .to({ x: 340.7 }, 10)
        .wait(40)
        .to({ x: 210.05 }, 10)
        .wait(10)
        .to(
          {
            scaleX: 0.9978,
            scaleY: 0.9624,
            x: 210,
            y: 338.75,
          },
          0
        )
        .wait(10)
        .to({ scaleX: 0.9982, scaleY: 0.9628, x: 210.05, y: 338.8 }, 0)
        .wait(19)
        .to(
          {
            regX: 51.6,
            x: 209.55,
            y: 338.2,
          },
          0
        )
        .to({ regX: 52.1, x: 210.05, y: 338.8 }, 40)
        .wait(1)
    );

    // Layer_8
    this.instance_10 = new lib.Symbol8();
    this.instance_10.setTransform(
      209.75,
      212.4,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.8
    );
    new cjs.ButtonHelper(this.instance_10, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_10)
        .wait(10)
        .to(
          {
            regX: 59.6,
            regY: 53.6,
            scaleX: 0.9978,
            scaleY: 0.9631,
            x: 217.45,
            y: 215.1,
          },
          0
        )
        .wait(10)
        .to(
          {
            regX: 52.1,
            regY: 50.8,
            scaleX: 0.9982,
            scaleY: 0.9628,
            x: 210.05,
            y: 212.4,
          },
          0
        )
        .wait(10)
        .to(
          {
            regY: 50.2,
            y: 338.8,
          },
          10
        )
        .wait(20)
        .to({ x: 79.35 }, 10)
        .wait(39)
        .to({ y: 338.2 }, 0)
        .to({ y: 338.8 }, 40)
        .wait(1)
    );

    // Layer_7
    this.instance_11 = new lib.Symbol7();
    this.instance_11.setTransform(
      470.5,
      212.4,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.8
    );
    new cjs.ButtonHelper(this.instance_11, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_11)
        .wait(20)
        .to(
          {
            regY: 50.2,
            y: 464.55,
          },
          10
        )
        .wait(30)
        .to({ y: 465.05 }, 10)
        .to({ regY: 50.8, y: 212.4 }, 10)
        .wait(70)
    );

    // Layer_6
    this.instance_12 = new lib.Symbol6();
    this.instance_12.setTransform(
      339.6,
      211.85,
      0.9982,
      0.9628,
      0,
      0,
      0,
      51.6,
      50.2
    );
    new cjs.ButtonHelper(this.instance_12, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_12)
        .wait(10)
        .to(
          {
            regX: 50.6,
            regY: 49.1,
            x: 338.6,
            y: 210.8,
          },
          0
        )
        .wait(10)
        .to(
          {
            regX: 51.6,
            regY: 50.2,
            x: 339.6,
            y: 211.85,
          },
          0
        )
        .wait(31)
        .to({ y: 213.05 }, 0)
        .wait(9)
        .to({ y: 211.85 }, 0)
        .wait(10)
        .to(
          {
            regY: 50.8,
            y: 212.4,
          },
          0
        )
        .wait(10)
        .to({ regY: 50.2, y: 211.85 }, 0)
        .wait(20)
        .to(
          {
            regX: 52.1,
            x: 339.55,
          },
          0
        )
        .wait(48)
        .to({ regX: 51.6, x: 339.6 }, 0)
        .wait(1)
        .to({ regX: 52.1, x: 339.55 }, 0)
        .wait(1)
    );

    // Layer_5
    this.instance_13 = new lib.Symbol5();
    this.instance_13.setTransform(
      209.25,
      87.3,
      0.9982,
      0.9628,
      0,
      0,
      0,
      51.6,
      50.2
    );
    new cjs.ButtonHelper(this.instance_13, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_13)
        .wait(30)
        .to({ y: 211.85 }, 10)
        .wait(30)
        .to(
          {
            regY: 50.8,
            y: 212.4,
          },
          0
        )
        .wait(10)
        .to({ regY: 50.2, y: 211.85 }, 0)
        .wait(29)
        .to({ x: 209.55 }, 0)
        .to({ x: 209.25 }, 40)
        .wait(1)
    );

    // Layer_4
    this.instance_14 = new lib.Symbol4();
    this.instance_14.setTransform(
      209.15,
      338.15,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.7,
      50.8
    );
    new cjs.ButtonHelper(this.instance_14, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_14)
        .to({ x: 79.95 }, 10)
        .wait(40)
        .to({ y: 212.4 }, 10)
        .wait(10)
        .to(
          {
            regY: 51.3,
            y: 212.9,
          },
          0
        )
        .wait(10)
        .to({ regY: 50.8, y: 212.4 }, 0)
        .wait(70)
    );

    // Layer_3
    this.instance_15 = new lib.Symbol3();
    this.instance_15.setTransform(
      338.85,
      87.85,
      0.9982,
      0.9628,
      0,
      0,
      0,
      51.6,
      50.8
    );
    new cjs.ButtonHelper(this.instance_15, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_15)
        .wait(100)
        .to(
          {
            regX: 52.1,
            x: 339.55,
          },
          0
        )
        .wait(48)
        .to({ regX: 51.6, x: 338.85 }, 0)
        .wait(2)
    );

    // Layer_2
    this.instance_16 = new lib.Symbol2();
    this.instance_16.setTransform(
      78.65,
      87.85,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.8
    );
    new cjs.ButtonHelper(this.instance_16, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_16)
        .wait(40)
        .to({ x: 209.35 }, 10)
        .wait(59)
        .to(
          {
            regX: 51.6,
            x: 209.55,
          },
          0
        )
        .to({ regX: 52.1, x: 209.35 }, 40)
        .wait(1)
    );

    // Layer_1
    this.instance_17 = new lib.Symbol1();
    this.instance_17.setTransform(
      79.35,
      212.4,
      0.9982,
      0.9628,
      0,
      0,
      0,
      52.1,
      50.8
    );
    new cjs.ButtonHelper(this.instance_17, 0, 1, 1);

    this.timeline.addTween(
      cjs.Tween.get(this.instance_17)
        .wait(10)
        .to(
          {
            regX: 52,
            scaleX: 0.9996,
            scaleY: 0.9717,
            x: 79.4,
            y: 212.85,
          },
          10
        )
        .to(
          {
            regX: 52.1,
            scaleX: 0.9982,
            scaleY: 0.9628,
            x: 79.35,
            y: 212.4,
          },
          10
        )
        .wait(20)
        .to({ y: 87.85 }, 10)
        .wait(90)
    );

    this._renderFirstFrame();
  }).prototype = p = new lib.AnMovieClip();
  p.nominalBounds = new cjs.Rectangle(
    376.7,
    343.4,
    292.59999999999997,
    238.89999999999998
  );
  // library properties:
  lib.properties = {
    id: '51C019BB19C64CE7A795558584D8096E',
    width: 700,
    height: 609,
    fps: 30,
    color: '#FFFFFF',
    opacity: 1.0,
    manifest: [
      { src: '/assets/bachtiles/Untitled1.jpg', id: 'Untitled1' },
      { src: '/assets/bachtiles/Untitled2.jpg', id: 'Untitled2' },
      { src: '/assets/bachtiles/Untitled3.jpg', id: 'Untitled3' },
      { src: '/assets/bachtiles/Untitled4.jpg', id: 'Untitled4' },
      { src: '/assets/bachtiles/Untitled5.jpg', id: 'Untitled5' },
      { src: '/assets/bachtiles/Untitled6.jpg', id: 'Untitled6' },
      { src: '/assets/bachtiles/Untitled7.jpg', id: 'Untitled7' },
      { src: '/assets/bachtiles/Untitled8.jpg', id: 'Untitled8' },
      { src: '/assets/bachtiles/Untitled9.jpg', id: 'Untitled9' },
      { src: '/assets/bachtiles/Untitled10.jpg', id: 'Untitled10' },
      { src: '/assets/bachtiles/Untitled11.jpg', id: 'Untitled11' },
      { src: '/assets/bachtiles/Untitled12.jpg', id: 'Untitled12' },
      { src: '/assets/bachtiles/Untitled13.jpg', id: 'Untitled13' },
      { src: '/assets/bachtiles/Untitled14.jpg', id: 'Untitled14' },
      { src: '/assets/bachtiles/Untitled15.jpg', id: 'Untitled15' },
      { src: '/assets/bachtiles/Untitled16.jpg', id: 'Untitled16' },
      { src: '/assets/bachtiles/Untitled17.jpg', id: 'Untitled17' },
    ],
    preloads: [],
  };

  // bootstrap callback support:

  (lib.Stage = function (canvas) {
    createjs.Stage.call(this, canvas);
  }).prototype = p = new createjs.Stage();

  p.setAutoPlay = function (autoPlay) {
    this.tickEnabled = autoPlay;
  };
  p.play = function () {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndPlay(this.getTimelinePosition());
  };
  p.stop = function (ms) {
    if (ms) this.seek(ms);
    this.tickEnabled = false;
  };
  p.seek = function (ms) {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndStop((lib.properties.fps * ms) / 1000);
  };
  p.getDuration = function () {
    return (this.getChildAt(0).totalFrames / lib.properties.fps) * 1000;
  };

  p.getTimelinePosition = function () {
    return (this.getChildAt(0).currentFrame / lib.properties.fps) * 1000;
  };

  an.bootcompsLoaded = an.bootcompsLoaded || [];
  if (!an.bootstrapListeners) {
    an.bootstrapListeners = [];
  }

  an.bootstrapCallback = function (fnCallback) {
    an.bootstrapListeners.push(fnCallback);
    if (an.bootcompsLoaded.length > 0) {
      for (var i = 0; i < an.bootcompsLoaded.length; ++i) {
        fnCallback(an.bootcompsLoaded[i]);
      }
    }
  };

  an.compositions = an.compositions || {};
  an.compositions['51C019BB19C64CE7A795558584D8096E'] = {
    getStage: function () {
      return exportRoot.stage;
    },
    getLibrary: function () {
      return lib;
    },
    getSpriteSheet: function () {
      return ss;
    },
    getImages: function () {
      return img;
    },
  };

  an.compositionLoaded = function (id) {
    an.bootcompsLoaded.push(id);
    for (var j = 0; j < an.bootstrapListeners.length; j++) {
      an.bootstrapListeners[j](id);
    }
  };

  an.getComposition = function (id) {
    return an.compositions[id];
  };

  an.makeResponsive = function (
    isResp,
    respDim,
    isScale,
    scaleType,
    domContainers
  ) {
    var lastW,
      lastH,
      lastS = 1;
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    function resizeCanvas() {
      var w = lib.properties.width,
        h = lib.properties.height;
      var iw = window.innerWidth,
        ih = window.innerHeight;
      // var pRatio = window.devicePixelRatio || 1,
      var pRatio = 1,
        xRatio = iw / w,
        yRatio = ih / h,
        sRatio = 1;
      if (isResp) {
        if (
          (respDim == 'width' && lastW == iw) ||
          (respDim == 'height' && lastH == ih)
        ) {
          sRatio = lastS;
        } else if (!isScale) {
          if (iw < w || ih < h) sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType == 1) {
          sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType == 2) {
          sRatio = Math.max(xRatio, yRatio);
        }
      }
      domContainers[0].width = w * pRatio * sRatio;
      domContainers[0].height = h * pRatio * sRatio;
      domContainers.forEach(function (container) {
        container.style.width = w * sRatio + 'px';
        container.style.height = h * sRatio + 'px';
      });
      stage = new createjs.Stage(canvas);
      stage.scaleX = pRatio * sRatio;
      stage.scaleY = pRatio * sRatio;
      lastW = iw;
      lastH = ih;
      lastS = sRatio;
      stage.tickOnUpdate = false;
      stage.update();
      stage.tickOnUpdate = true;
    }
  };
  an.handleSoundStreamOnTick = function (event) {
    if (!event.paused) {
      var stageChild = stage.getChildAt(0);
      if (!stageChild.paused || stageChild.ignorePause) {
        stageChild.syncStreamSounds();
      }
    }
  };
}
