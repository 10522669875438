<template>
  <div id="animation_container">
    <canvas id="canvas" ref="canvas"></canvas>
    <div id="dom_overlay_container"></div>
  </div>
</template>

<script>
// This component was converted from the HTML file from adobe animate export. It shouldn't need to change.
// If you are updating new js, you will need to do a few things:
//    1. Update the images manifest to the correct image path
//    2. import/declare createjs at the top of the page
//    3. Convert the anonymous function to an exported function with the name BachTilesCreate
//    4. Change 'var pRatio = window.devicePixelRatio || 1' to just 'var pRatio = 1;' near the end of the document
//    5. Add 'stage = new createjs.Stage(canvas);' above 'stage.scaleX = pRatio * sRatio;' also near the end of the document
// That should be all you need unless adobe overhauls the js (which is honestly unlikely)

// Get the createjs instance from window object (it's loaded from the CDN because it has no module support...)
const createjs = window.createjs;
import { BachTilesCreate } from './BachTilesCreate.js';
import $eventHub from '@/components/EventHub';

export default {
  name: 'BachTiles',
  props: {
    msg: String,
  },
  data() {
    return {
      canvas: undefined,
      anim_container: undefined,
      dom_overlay_container: undefined,
      exportRoot: undefined,
      stage: undefined,
      AdobeAn: {},
    };
  },
  mounted() {
    BachTilesCreate(
      createjs,
      this.AdobeAn,
      this.canvas,
      this.stage,
      this.exportRoot
    );
    // this.startLoading();
    this.init();
  },
  methods: {
    init: function () {
      this.startLoading();
      this.canvas = document.getElementById('canvas');

      this.anim_container = document.getElementById('animation_container');
      this.dom_overlay_container = document.getElementById(
        'dom_overlay_container'
      );
      const comp = this.AdobeAn.getComposition(
        '51C019BB19C64CE7A795558584D8096E'
      );
      let lib = comp.getLibrary();
      const loader = new createjs.LoadQueue(false);

      const self = this;
      loader.addEventListener('fileload', function (evt) {
        self.handleFileLoad(evt, comp);
      });

      loader.addEventListener('complete', function (evt) {
        self.handleComplete(evt, comp);
        self.stopLoading();
      });

      lib = comp.getLibrary();
      loader.loadManifest(lib.properties.manifest);
    },
    startLoading: function () {
      $eventHub.$emit('componentLoading');
    },
    stopLoading: function () {
      $eventHub.$emit('componentLoaded');
    },
    handleFileLoad: function (evt, comp) {
      const images = comp.getImages();
      if (evt && evt.item.type == 'image') {
        images[evt.item.id] = evt.result;
      }
    },
    handleComplete: function (evt, comp) {
      //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
      const lib = comp.getLibrary();
      const ss = comp.getSpriteSheet();
      const queue = evt.target;
      const ssMetadata = lib.ssMetadata;

      for (let i = 0; i < ssMetadata.length; i++) {
        ss[ssMetadata[i].name] = new createjs.SpriteSheet({
          images: [queue.getResult(ssMetadata[i].name)],
          frames: ssMetadata[i].frames,
        });
      }

      this.exportRoot = new lib.animationheader_HTML5Canvas();
      this.stage = new lib.Stage(this.canvas);
      // this.stage.enableMouseOver();

      //Registers the "tick" event listener.
      const self = this;
      const fnStartAnimation = function () {
        self.stage.addChild(self.exportRoot);
        createjs.Ticker.framerate = lib.properties.fps;
        createjs.Ticker.addEventListener('tick', self.stage);
      };

      //Code to support hidpi screens and responsive scaling.
      this.AdobeAn.makeResponsive(false, 'both', false, 1, [
        this.canvas,
        self.anim_container,
        self.dom_overlay_container,
      ]);

      this.AdobeAn.compositionLoaded(lib.properties.id);
      fnStartAnimation();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';

/* TODO: Need to figure out centering of animation */
#animation_container {
  background-color: rgba(255, 255, 255, 1);
  width: 800px;
  height: 696px;

  @include touch {
    max-width: 100vw !important;
    max-height: 300px !important;
    margin: auto;
    transform: translate(15%);
  }
}

#canvas {
  position: absolute;
  display: block;
  background-color: rgba(255, 255, 255, 1);
  width: 800px;
  height: 696px;
}

#dom_overlay_container {
  pointer-events: none;
  overflow: hidden;
  width: 800px;
  height: 696px;
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
}
</style>
