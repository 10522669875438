



























































































































import Vue from 'vue';
import Wizard from '@/components/Wizard.vue';
import BachTiles from '@/components/BachTiles/BachTiles.vue';

// loads as web component
require('lite-youtube-embed/src/lite-yt-embed.js');

export default Vue.extend({
  components: {
    BachTiles,
    Wizard,
  },
  data() {
    return {
      isVideoModalActive: false,
      steps: [
        {
          index: 1,
          title: {
            style: 'h3',
            html: 'Build Your Ensemble',
            plain: 'Build Your Ensemble',
          },
        },
        {
          index: 2,
          title: {
            style: 'h3',
            html: 'Choose Your Piece(s)',
            plain: 'Choose Your Piece(s)',
          },
        },
        {
          index: 3,
          title: {
            style: 'h3',
            html: 'Finalize Your Configuration',
            plain: 'Finalize Your Configuration',
          },
        },
      ],
    };
  },
  mounted() {
    if (
      this.$route.name === 'See in Action' &&
      this.$route.meta?.youtube === true
    ) {
      this.isVideoModalActive = true;
    }
  },
  computed: {
    stepIndex: {
      get: function (): string {
        return this.$store.state.stepIndex;
      },
      set: function (newVal: number): void {
        this.$store.commit('changeStepIndex', newVal);
      },
    },
  },
  methods: {
    removeYtRoute() {
      window.history.pushState({}, document.title, '/');
    },
    gotoStep: function (index: number) {
      this.stepIndex = index;
    },
    goto: function (refName: string | number) {
      let ref = this.$refs[refName];
      if (ref instanceof HTMLElement) {
        window.scrollTo({ top: ref.offsetTop, behavior: 'smooth' });
      }
    },
  },
});
